import React, { useEffect, useState } from 'react';
import '../css/Home.css';
import FileUploadComponent from '../components/PdfUploader';
import axios from 'axios';

let apiUrl = process.env.REACT_APP_API_URL;
let serviceUrl = process.env.REACT_APP_SERVICE_URL;

const SuggestQuestion = () => {
    const [inputs, setInputs] = useState(['']);

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await axios.get(`${apiUrl}/faq`);
                console.log('faq response :', response);
                setInputs(response.data.faq.length > 0 ? response.data.faq : ['']);
            } catch (error) {
                console.error('FAQ 데이터 로딩 에러:', error);
            }
        };

        fetchFAQ();
    }, []);

    const handleChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value;
        setInputs(newInputs);
    };

    const addInput = () => {
        setInputs([...inputs, '']);
    };

    const removeInput = async (index) => {
        const faqData = inputs[index];

        const response = await fetch(`${apiUrl}/faq/${faqData}`, {
            method: 'DELETE'
        });

        if (response.ok) {
            const newInputs = inputs.filter((_, i) => i !== index);
            setInputs(newInputs);
        } else {
            console.error('Failed to delete the input on the server:', await response.text());
        }
    };

    const handleSubmit = async () => {
        console.log('faq inputs :', inputs)
        try {
            const response = await axios.put(`${apiUrl}/faq`, {
                faq: inputs,
            });
            console.log('FAQ response :', response);
            alert('자주 찾는 질문이 성공적으로 제출되었습니다.');
        } catch (error) {
            console.error('질문 제출 에러:', error);
            alert('자주 찾는 질문 제출 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className='suggest-section'>
            {inputs.map((input, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <input
                        key={index}
                        value={input}
                        onChange={(e) => handleChange(index, e)}
                        placeholder='자주 찾는 질문을 입력하세요'
                    />
                    <button onClick={() => removeInput(index)} style={{ 
                        marginLeft: '10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '10px'
                    }}>
                            삭제
                            {/* Del */}
                    </button>
                </div>
            ))}
            <div className='suggest-btn'>
                <button 
                    onClick={addInput}
                    style={{backgroundColor:'skyblue', fontSize: '25px', borderRadius: '100px', width: '50px', height: '50px'}}
                >
                    +
                </button>
                {inputs.length===0 && (<button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#3ebb3d', color:'white'}}
                >
                    등록
                </button>)}
                {inputs.length > 0 && (<button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#3ebb3d', color:'white'}}
                >
                    {/* Update */}
                    업데이트
                </button>)}
            </div>
        </div>
    );
}

const Home = () => {
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [filesEmbeddingStatus, setFilesEmbeddingStatus] = useState([]);
    const [lastClicked, setLastClicked] = useState(0);

    useEffect(() => {
        axios.get(`${apiUrl}/pdf/upload`)
            .then(response => {
                console.log('get pdf files response :', response)
                if (response.data && response.data.result.length > 0) {
                    const filesOnServer = response.data.result.map(file => file.file_name);
                    const statuses = response.data.result.map(file => file.status);
                    console.log('files on server >>', filesOnServer)
                    console.log('embedding statuses >>', statuses)

                    setPdfFileNames(filesOnServer);
                    setFilesEmbeddingStatus(statuses);

                    setUploadSuccess(true);
                    setPdfAlreadyRegistered(true);
                } else {
                    setPdfFileNames([]);
                    setFilesEmbeddingStatus([]);
                }
            })
            .catch(error => {
                console.error('Error from fetching PDF file names & embedding status >>', error);
                setPdfFileNames([]);
                setFilesEmbeddingStatus([]);
            });
    }, []);

    const [pdfAlreadyRegistered, setPdfAlreadyRegistered] = useState(
        !!pdfFileNames && pdfFileNames.length > 0
    );

    const handleUploadSuccess = (isSuccess, newFileNames) => {
        if (isSuccess) {
            setPdfFileNames(prevNames => [...prevNames, ...newFileNames]);
            setFilesEmbeddingStatus(prevStatuses => [
                ...prevStatuses,
                ...newFileNames.map(() => 'STARTED') // 새 파일에 대한 상태를 'STARTED'로 설정
            ]);
            console.log('emb. status update >>', filesEmbeddingStatus)
            localStorage.setItem('pdf_file_name', JSON.stringify([...pdfFileNames, ...newFileNames]));
            setUploadSuccess(true);
            setPdfAlreadyRegistered(true);
        }
    };

    const handleRemovePdfFile = async (index) => {
        const fileNameToDelete = pdfFileNames[index];
    
        const isConfirmed = window.confirm(
            `다음 파일을 정말로 삭제하시겠습니까?\n'${fileNameToDelete}'`
        );
    
        if (!isConfirmed) {
            return;
        }
    
        try {
            const response = await axios.delete(`${apiUrl}/pdf/${fileNameToDelete}`);
            if (response.status === 200) {
                console.log('File is successfully deleted:', fileNameToDelete);
                
                // 로컬 상태에서도 파일 및 해당 파일 상태 삭제
                const updatedFiles = [...pdfFileNames];
                const updatedStatuses = [...filesEmbeddingStatus];
                updatedFiles.splice(index, 1);
                updatedStatuses.splice(index, 1);
                setPdfFileNames(updatedFiles);
                setFilesEmbeddingStatus(updatedStatuses);
                localStorage.setItem('pdf_file_name', JSON.stringify(updatedFiles));
    
                alert('파일이 성공적으로 삭제되었습니다.');
            } else {
                throw new Error('Failed to delete the file from the server.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('파일 삭제 중 오류가 발생했습니다. 나중에 다시 시도하세요.');
        }
    };
    

    // pdf 임베딩 상태를 체크하는 함수
    const checkPdfStatus = () => {
        const now = new Date().getTime();
        const timeSinceLastClick = now - lastClicked;

        if (timeSinceLastClick >= 5000) { // 5초 제한
            setLastClicked(now);
            axios.get(`${apiUrl}/pdf/upload`)
                .then(response => {
                    if (response.data && response.data.result.length > 0) {
                        const fileNames = response.data.result.map(file => file.file_name);
                        const statuses = response.data.result.map(file => file.status);
                        setPdfFileNames(fileNames);
                        setFilesEmbeddingStatus(statuses);
                    } else {
                        setPdfFileNames([]);
                        setFilesEmbeddingStatus([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching PDF statuses >>', error);
                    setPdfFileNames([]);
                    setFilesEmbeddingStatus([]);
                });
            alert('파일별 학습 진행상황을 업데이트 합니다.')
        } else {
            const remainingTime = 5 - Math.floor(timeSinceLastClick / 1000);
            alert(`${remainingTime} 초 후 확인 가능`);
        }
    };

    return (
        <div className='wrap' style={{background: 'burlywood'}}>
            <div className='container1' style={{display:'block', background: 'burlywood'}}>
            <div className='home-container'>
                <p className='section-title'>서비스 소개(매뉴얼)</p>
                {/* <p className='section-title'>Service Introduction (Manual)</p> */}
                {/* 메뉴얼 pdf 링크 걸어서 새창으로 보이기 */}
                <button className='link-btn' onClick={() => window.open(`${apiUrl}/file/menual/camel_kepco_menual.pdf`)}>서비스 매뉴얼 바로가기</button>
                {/* <button className='link-btn' onClick={() => window.open(`${apiUrl}/file/menual/camel_kepco_menual.pdf`)}>Service Manual</button> */}
            </div>

            <div className='home-container'>
                <p className='section-title'>PDF List</p>
                <p>동일한 이름의 PDF 파일은 업로드 할 수 없습니다.</p>
                <p>기존 파일 삭제후 업로드 해주시기 바랍니다.</p>
                {/* <p>You cannot upload a PDF file with the same name.</p> */}
                {/* <p>Please delete the existing file before uploading.</p> */}
                {pdfAlreadyRegistered && (
                    <div className='data-already-uploaded' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div className='uploaded-data-list' style={{
                            fontWeight: 600, 
                            maxWidth: '90%',
                            height: '100%',
                            backgroundColor: '#a3773f8f',
                            display: 'block',
                            padding: '10px',
                            marginTop : '5px',
                            marginBottom: '10px',
                            borderRadius: '10px'
                        }}>
                            {/* <p>업로드한 파일 목록</p> */}
                            <p>Uploaded files</p>
                            <ul style={{ paddingLeft:0 }}>
                                {pdfFileNames.map((fileName, index) => (
                                    <li key={index} style={{
                                        display: "flex", 
                                        alignItems: "center", 
                                        justifyContent: "space-between", 
                                        marginBottom: '5px',
                                        background: 'white',
                                        margin: '5px',
                                        padding: '3px',
                                        textAlign: 'left',
                                        borderRadius: '5px'
                                    }}>
                                        <span style={{
                                            flex: "1 1 auto",
                                            overflow: 'hidden', 
                                            textOverflow: 'ellipsis', 
                                            whiteSpace: 'nowrap', 
                                            marginRight: '10px'
                                        }}>
                                            {fileName}
                                        </span>
                                        <img src={
                                            filesEmbeddingStatus[index] === 'SUCCESS' ? '/img/emb_success.png' :
                                            filesEmbeddingStatus[index] === 'STARTED' ? '/img/emb_loading.gif' :
                                            filesEmbeddingStatus[index] === 'FAILED' ? '/img/emb_failed.png' : '/img/emb_loading.gif'
                                        } alt="Status Icon" style={{ width: '20px', height: '20px' }} />
                                        
                                        {filesEmbeddingStatus[index] === 'STARTED' ? (
                                            <button style={{
                                                flex: "0 0 50px",
                                                marginLeft: '10px',
                                                backgroundColor: 'transparent',
                                                color: 'black',
                                                borderRadius: '5px',
                                                cursor: 'not-allowed',
                                                fontWeight: 600
                                            }} disabled>
                                                학습중
                                            </button>
                                        ) : (
                                            <button style={{
                                                flex: "0 0 50px",
                                                marginLeft: '10px',
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '5px'
                                            }} onClick={() => handleRemovePdfFile(index)}>
                                                {/* 삭  제 */}
                                                Del
                                            </button>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <label className='pdf-process-check' style={{ cursor: 'pointer' }}>
                                <p style={{ marginTop: '15px' }}>PDF 학습 진행상태 확인</p>
                                {/* <p style={{ marginTop: '15px' }}>Check PDF learning progress</p> */}
                                <img src='/img/refresh.png' alt='embedding-refresh' style={{ width: '50px' }} />
                                <button onClick={checkPdfStatus} style={{ display: 'none' }}></button>
                            </label>
                        </div>
                    </div>
                )}
                
                <div className='home-container'>
                    <p className='section-title'>PDF 업로드</p>
                    {/* <p className='section-title'>PDF upload</p> */}
                    <div className='upload-area'>
                        <FileUploadComponent onUploadSuccess={handleUploadSuccess} />
                    </div>
                </div>
            </div>
            
            <div className='home-container'>
                <p className='section-title'>자주 찾는 질문 List</p>
                {/* <p className='section-title'>Frequently Asked Questions</p> */}
                <SuggestQuestion />
            </div>

            {uploadSuccess && (
                <>
                    <div className='home-container'>
                        <p className='section-title'>Chatbot</p>
                        {/* <button className='link-btn' onClick={() => window.open(`${serviceUrl}/chatbot`)}>Chatbot Shortcut</button> */}
                        <button className='link-btn' onClick={() => window.open(`${serviceUrl}/chatbot`)}>챗봇 바로가기</button>
                    </div>

                    <div className='home-container'>
                        <p className='section-title'>History</p>
                        <a href="/history" target="_blank" rel="noopener noreferrer">
                            {/* <button className="link-btn">View Question History</button> */}
                            <button className="link-btn">질문 이력 확인</button>
                        </a>
                    </div>
                </>
            )}
            </div>
        </div>
    );
}

export default Home;