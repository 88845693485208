import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/App.css';

let llmName = process.env.REACT_APP_LLM_NAME;
let apiUrl = process.env.REACT_APP_API_URL;


const Chatbot = () => {
    const [tab, setTab] = useState('chat'); // 추가: 탭 상태 관리
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false); // 답변 대기중 스피너 출력
    const [isLoadingVoice, setIsLoadingVoice] = useState(false); // 답변 대기중 스피너 출력
    const [selectedModel, setSelectedModel] = useState(llmName); // 선택된 AI 모델 이름
    const [showPopup, setShowPopup] = useState(false); // 팝업 상태 관리
    
    // suggestion chip에 들어갈 faq 관리
    const [rawFaqs, setRawFaqs] = useState([]);
    const [faqs, setFaqs] = useState([]); 

    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);


    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();
    
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
        window.removeEventListener('resize', handleResize);
        };
      }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행

    // 메세지 전송 함수
    const sendMessage = (messageToSend = text) => {
        // 입력 요소의 포커스 해제
        document.activeElement.blur();
        
        // 입력된 텍스트 또는 함수에 전달된 메시지를 사용
        const messageContent = messageToSend.trim();
        if (!messageContent) return;
        
        setMessages(prevMessages => [...prevMessages, { text: messageContent, sender: 'user', tabInfo: tab}]);
        setText('');
        setIsLoading(true); // 로딩 시작
        
        console.log("tab:", tab); // 탭선택 확인

        // 챗봇 응답 로직을 여기에 추가
        const answerInfo = {
            'user_query': messageContent,
            'model': selectedModel,
            'tab': tab,
            'lang': 'kor'
            };
            console.log('Current answerInfo:', answerInfo); // 현재 answerInfo 로그
            
            axios.post(`${apiUrl}/chat`, answerInfo, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log('Response:', response);

                // extract response
                var output = response.data;
                var output_chat = output["output_chat"];
                var output_chunk = output["output_chunk"];
                // pdf 출력 개수 제한
                output_chunk = output_chunk.slice(0, 3);

                if (output_chat=='None'){
                    // search
                    console.log('검색엔진 실행')
                    output_chat = `<p><strong>PDF 검색 결과 </strong><br></p>`;

                    // chunk 데이터 파싱
                    output_chunk.forEach(function(chunk) {
                        var context = chunk.context;
                        var file_name = chunk.file_name;
                        var page = chunk.page;

                        // context가 50자를 초과하는 경우 잘라내기
                        if (context.length > 100) {
                            context = context.slice(0, 100); // 50자 이상이면 마지막에 '...'을 추가하여 표시
                        }

                        // chunk의 filename과 context를 동시에 text에 보내기
                        // console.log("context : ", context);
                        var contextWithoutNewlines = context.replace(/(\r\n|\n|\r)/gm, ' '); // 줄바꿈 제거


                        // 줄바꿈을 기준으로 텍스트를 문장으로 분리
                        var sentences = context.split(/[\r\n]+/);

                        // 가장 긴 문장 찾기
                        var longestSentence = sentences.reduce((longest, current) => {
                        return current.length > longest.length ? current : longest;
                        }, "");
                        // console.log('longestSentence >>', longestSentence);
                        
                        // 특수 기호 제거()
                        longestSentence = longestSentence.replace(/^[^가-힣]+/, '');
                        // 특수기호 이후의 모든 문자 제거
                        longestSentence = longestSentence.replace(/[^\w\s가-힣\(\)\[\],]+.*$/, '');

                        console.log('longestSentence 결과 >>', longestSentence);
                        var text = `<p><strong>File: ${file_name}</strong> <img src="img/pdf.png" alt="pdf파일"/><strong><br><p class='page_text'>${page} Page</p></strong><br><strong>내용:</strong>${contextWithoutNewlines}</p>`;

                        // chunk 왼쪽 버블(bot영역)에 추가 생성
                        setMessages(prevMessages => [...prevMessages, { text: text, sender: 'bot_chunk', page:page, copy: longestSentence, raw_text : contextWithoutNewlines, tabInfo: 'search' }]);

                    });
                } else {
                    console.log('챗봇 실행')
                    // chat
                    output_chat = `<p><strong>AI챗봇 답변</strong><br>${output_chat}</p>`;
                    // 챗봇 답변 메시지 추가
                    setMessages(prevMessages => [...prevMessages, { text: output_chat, sender: 'bot', tabInfo: 'chat' }]);
                }

                setIsLoading(false); // 로딩 종료
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false); // 로딩 종료
            });
    };

    // 라운드 칩을 유저 데이터에서 받아와서 마운트
    const fetchFAQs = async () => {
        try {
            const response = await axios.get(`${apiUrl}/faq`);
            const faqsData = response.data.faq !== null ? response.data.faq : [];
            if (faqsData) {
                setRawFaqs(faqsData);
                let randomFaqs = faqsData.sort(() => Math.random() - 0.5).slice(0,5);
                setFaqs(randomFaqs);
            } else {
                setFaqs([]);
            }
        } catch (error) {
            console.error('FAQ 데이터 로딩 에러:', error); 
            setFaqs([]);
        }
    };

    useEffect(() => {
        fetchFAQs();
    }, []);

    const updateChipsWrapperContent = (rawFaqs) => {
        if (rawFaqs) {
            let randomFaqs = rawFaqs.sort(() => Math.random() - 0.5).slice(0,4);
            setFaqs(randomFaqs);
            console.log('Faqs :', faqs)
        } else {
            setFaqs([]);
            console.log('Faqs error :', faqs)
        }
    }

    // 라운드 칩 클릭 시 메시지 전송
    const handleChipClick = (message) => {
        updateChipsWrapperContent(rawFaqs)
        sendMessage(message);
    };

    // 현재 시간을 포맷팅하여 반환하는 함수
    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        
        // 시간을 12시간 형식으로 변환
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const period = hours >= 12 ? '오후' : '오전';
        
        // 0을 추가하여 두 자리 숫자로 만듦
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${period} ${formattedHours}:${formattedMinutes}`;
    };

    // pdf를 웹브라우저에서 여는 함수
    const openPDF = (text, pageNumber) => {
        // 'File Name' 추출
        let fileName = /File: (.*?)<br>/.exec(text)[1];
        fileName = /[^\/]+(?=\.pdf)/.exec(fileName)[0] + ".pdf";

        // PDF 파일이 저장된 경로
        const pdfUrl = `http://camel-kepco-api.o2o.kr/file/${fileName}#page=${pageNumber}`;

        // 웹 브라우저에서 PDF 파일 열기
        window.open(pdfUrl, '_blank');
    };

    // 텍스트를 클립보드에 복사
    const copyTextToClipboard = (text) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        // 팝업 표시
        setShowPopup(true);

        // 일정 시간 후 팝업 숨기기
        setTimeout(() => {
            setShowPopup(false);
        }, 500); // 0.5초 후에 숨김
    };

    return (
        <div className="App">
            <div className={`chat-container ${isLoading ? 'blurred' : ''}`}>
                {/* 채팅 화면 */}
                <div className='wrap'>
                    <div className='container'>
                        {/* 로딩 인디케이터 1 */}
                        {isLoading && (
                            <div className="loading-overlay">
                                <img src="img/spinner_01.gif" alt="로딩중" className="loading-spinner"/>
                            </div>
                        )}
    
                        {/* 로딩 인디케이터 2 */}
                        {isLoadingVoice && (
                            <div className="loading-overlay">
                                <img src="img/spinner_02.gif" alt="로딩중" className="loading-spinner"/>
                            </div>
                        )}
    
                        <div className='msger'>
    
                            {/* 헤더 */}
                            <header className='msger-header'>
                                <div className='msger-header-title'>
                                    <div className='header-logo-frame'>
                                        {/* <img src='img/kepco_logo_01.jpg' alt="Logo" style={{ width: '160px' }}></img> */}
                                        <img src='img/O2O_logo.png' alt="Logo" style={{ width: '50px' }}></img>
                                    </div>
                                </div>
                                {/* 추가된 탭 구성 */}
                                <div className="msger-header-tabs">
                                    <button className={`tab ${tab === 'chat' ? 'active' : ''}`} onClick={() => setTab('chat')}>AI 채팅</button>
                                    <button className={`tab ${tab === 'search' ? 'active' : ''}`} onClick={() => setTab('search')}>PDF 검색</button>
                                </div>
                            </header>
    
                            {/* 조건부 렌더링으로 채팅창 및 검색창 구분 */}
                            {tab === 'chat' ? (
                                <div>
                                    <main className='msger-chat msger-chat-frame'>
                                        <div type="text" className='greeting'>질문에 해당하는 정보를 바탕으로 AI 챗봇이 답변을 드리겠습니다.</div>
                                        {/* <div type="text" className='greeting'>Ask anything through this chatbot!</div> */}
                                        <section className='msger-chat-prompt'>
                                            <div className='scroll-prompt'>
                                                {messages.filter(message => message.tabInfo === 'chat').map((message, index) => (
                                                    <div key={index}>
                                                        <div className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'} ${message.sender === 'user' ? '' : 'msg-bubble-left'}`}>
                                                            <div className='user-say-text' dangerouslySetInnerHTML={{ __html: message.text }}></div>
                                                            <div className="msg-time">{getCurrentTime()}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div ref={messagesEndRef} />
                                                {isLoading && <div className="loading-indicator"></div>}
                                            </div>
                                        </section>
                                    </main>
                                </div>
                            ) : (
                                <div>
                                    <main className='msger-chat msger-chat-frame'>
                                        {/* 검색 탭 컨텐츠 */}
                                        <div type="text" className='greeting'>검색하신 내용에 해당하는 PDF를 찾아 드립니다.</div>
                                        <section className='msger-chat-prompt'>
                                            <div className='scroll-prompt'>
                                                {messages.filter(message => message.tabInfo === 'search').map((message, index) => (
                                                    <div key={index}>
                                                        <div className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'} ${message.sender === 'user' ? '' : 'msg-bubble-left'}`}
                                                        onClick={() => { if (message.sender === 'bot_chunk') { openPDF(message.text, message.page); } }}
                                                        >
                                                            <div className='user-say-text' dangerouslySetInnerHTML={{ __html: message.text }}></div>
                                                            <div className="msg-time">{getCurrentTime()}</div>
                                                        </div>
                                                            <div className='copy-frame'>
                                                            {message.sender === 'bot_chunk' && (
                                                                <button className='copy-button' onClick={() => copyTextToClipboard(message.copy)}>복사</button>
                                                                
                                                            )}
                                                            </div>
                                                            {/* 팝업 */}
                                                            {showPopup && (
                                                                    <div style={{
                                                                        position: 'fixed',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        padding: '20px',
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                                        color: '#fff',
                                                                        borderRadius: '10px',
                                                                        zIndex: 1001
                                                                    }}>
                                                                        복사되었습니다!
                                                                    </div>
                                                                )}
                                                    </div>
                                                ))}
                                                <div ref={messagesEndRef} />
                                                {isLoading && <div className="loading-indicator"></div>}
                                            </div>
                                        </section>
                                    </main>
                                </div>
                            )}

    
                            <form className='msger-form' onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                                <div className='chips-wrapper' id='chips-wrapper'>
                                    {faqs.map((faq, index) => (
                                        <div key={index} className="rounded-chip" onClick={() => handleChipClick(faq)}>
                                            {faq}
                                        </div>
                                    ))}
                                </div>
                                <div className='msger-inputarea'>
                                    <img style={{ float: 'left', paddingTop: '12px', marginRight: '10px' }} src="img/home.svg" alt="home" onClick={() => window.location.reload()}></img>
                                    <input className="msger-input" id="msger-input" type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder='무엇이든 물어보세요'/>
                                    {/* <input className="msger-input" id="msger-input" type="text" value={text} onChange={(e) => setText(e.target.value)} placeholder='Write your message'/> */}
                                    <button className="send-btn" type="submit">
                                        <img style={{ width: '35px', paddingTop: '0px' }} src="img/send.svg" alt="send"></img>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
    };

export default Chatbot;