import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Chatbot from './pages/Chatbot';
import Intro from './pages/Intro';
import Admin from './pages/Admin';
import History from './pages/History';

function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Intro />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/chatbot" element={<Chatbot />} />
                  <Route path="/history" element={<History />} />
              </Routes>
          </BrowserRouter>
      </div>
  );
}

export default App;