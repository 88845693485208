import React from 'react';
import '../css/Intro.css';

let serviceUrl = process.env.REACT_APP_SERVICE_URL;

const Intro = () => {
	return (
		<div className='wrap' style={{backgroundColor:"white", minHeight:"100vh"}}>
            <div className='intro-items'>
                {/* <img src="img/kepco_logo_01.jpg" alt="Logo" style={{width: '320px', margin:'auto'}}></img> */}
                <img src="img/O2O_logo.png" alt="Logo" style={{width: '240px', margin:'auto'}}></img>
                <p className='intro-title'>검색증강생성(RAG) 기반 카멜 챗봇 솔루션</p>
                {/* <p className='intro-title'>RAG based chatbot “Camel”</p> */}

                <button className='intro-btn' onClick={() => window.open(`${serviceUrl}/chatbot`)}>챗봇 바로가기</button>
                <button className='intro-btn' onClick={() => window.open(`${serviceUrl}/admin`)}>관리 페이지 바로가기</button>
                {/* <button className='intro-btn' onClick={() => window.open(`${serviceUrl}/chatbot`)}>Chatbot</button> */}
                {/* <button className='intro-btn' onClick={() => window.open(`${serviceUrl}/admin`)}>Admin page</button> */}

                <p className='intro-text-basic'>Powered by O2O</p>
            </div>
            
		</div>
	);
};

export default Intro;