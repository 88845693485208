import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../css/History.module.css';

let apiUrl = process.env.REACT_APP_API_URL;

const History = () => {
    const [historyList, setHistoryList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 한 페이지당 보일 질문의 수
    const totalPages = Math.ceil(historyList.length / itemsPerPage); // 총 페이지 수

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await axios.get(`${apiUrl}/question`);
                console.log('history response :', response);
                setHistoryList(response.data.data.reverse());
            } catch (error) {
                console.error('history fetch error :', error);
            }
        };

        fetchHistory();
    }, []);

    // 페이지네이션을 위한 질문들 계산
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = historyList.slice(indexOfFirstItem, indexOfLastItem);

    // 페이지 번호 변경 핸들러
    const paginate = pageNumber => setCurrentPage(pageNumber);

    // 질문 삭제 핸들러
    const deleteQuestion = async (id) => {
        try {
            await axios.delete(`${apiUrl}/question/${id}`);
            setHistoryList(historyList.filter(item => item.id !== id));
            alert('삭제되었습니다.')
        } catch (error) {
            console.error('Delete error:', error);
        }
    };

    return (
        <div className={styles.historyContainer}>
            <h2 style={{ marginBottom: '50px' }}>질문 History</h2>
            <div>
                <ul style={{ paddingLeft: 0 }}>
                    {historyList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((questionData, index) => (
                        <li key={index} className={styles.questionCard}>
                            <div className={styles.cardContent}>
                                <span className={styles.date}>{new Date(questionData.date).toLocaleString()}</span>
                                <span className={styles.question}>{questionData.question}</span>
                                <button className={styles.deleteButton} onClick={() => deleteQuestion(questionData.id)}>삭제</button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.pagination}>
                <button onClick={() => paginate(1)} disabled={currentPage === 1}>&#124;&lt;</button>
                <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>&lt;</button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => paginate(i + 1)}
                        className={currentPage === i + 1 ? styles.active : ''}
                    >
                        {i + 1}
                    </button>
                ))}
                <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>&gt;</button>
                <button onClick={() => paginate(totalPages)} disabled={currentPage === totalPages}>&gt;&#124;</button>
            </div>
        </div>
    );
}

export default History;
